import React from "react"
import { Link } from "gatsby"
import { Seo } from "../components/Seo/Seo"

export default function ContactPage() {
  return (
    <React.Fragment>
      <Seo
        title="BIGEYE Direct Herndon, VA. Full-service direct mail producer located near Washington DC"
        description="Direct Mail Processing Facility located in Herndon Virginia. Secure mail facility."
      />
      <div id="wrapper">
        <div id="topper">
          <div id="logo">
            <Link to="/">
              <img
                src="/wordmark-dark.svg"
                width="234"
                height="108"
                alt="Big Eye Direct"
              />
            </Link>
          </div>
          <div id="contact">
            <h2>703-955-3017</h2>
            <p>
              <a href="mailto:damon@bigeyedirect.com">info@bigeyedirect.com</a>
            </p>
          </div>
        </div>
        <div id="content">
          <div id="nav">
            <ul>
              <li>
                <Link to="/">HOME</Link>
              </li>
              <li>
                <Link to="/services/">SERVICES</Link>
              </li>
              <li>
                <Link to="/facility/">OUR FACILITY</Link>
              </li>
              <li>
                <Link to="/security/">SECURITY</Link>
              </li>
              <li>
                <Link to="/about/">ABOUT US</Link>
              </li>
              <li className="endNavactive">
                <Link to="/contact/">CONTACT US</Link>
              </li>
            </ul>
          </div>
          <div className="clear"></div>
          <div id="flasharea">
            {" "}
            <img src="/images/photo_contact_new.jpg" width="926" height="216" />
          </div>
          <div id="textarea">
            <div id="info_left">
              <h2>Contact Us</h2>
              <p>We welcome your inquiries:</p>

              <h3>
                BIGEYE Direct, Inc
                <br />
                13860 Redskin Dr
                <br />
                Herndon, VA 20171
                <br />
                703-955-3017 (main)
                <br />
                866-654-2797 (fax)
                <br />
                <a href="mailto:damon@bigeyedirect.com">
                  info@bigeyedirect.com
                </a>
              </h3>
              <p>&nbsp;</p>
              <p>
                <a
                  rel="noopener noreferrer"
                  href="http://www.facebook.com/pages/Bigeye-Direct-Inc/#!/pages/Bigeye-Direct-Inc/127236331062"
                  target="_blank"
                >
                  <img
                    src="/images/facebook_btn.gif"
                    width="139"
                    height="21"
                    alt="Find Us On Facebook"
                  />
                </a>
              </p>
            </div>

            <div id="map">
              <a
                rel="noopener noreferrer"
                href="http://www.facebook.com/pages/Bigeye-Direct-Inc/127236331062"
                target="_blank"
              >
                <iframe
                  width="300"
                  height="300"
                  frameBorder="0"
                  scrolling="no"
                  marginHeight={0}
                  marginWidth={0}
                  src="http://maps.google.com/maps?f=q&amp;source=s_q&amp;hl=en&amp;geocode=&amp;q=13860+Redskin+Dr+Herndon,+VA+20171&amp;sll=37.0625,-95.677068&amp;sspn=49.176833,77.255859&amp;ie=UTF8&amp;hq=&amp;hnear=13860+Redskin+Dr,+Herndon,+Fairfax,+Virginia+20171&amp;ll=38.943523,-77.425804&amp;spn=0.020027,0.025749&amp;z=14&amp;iwloc=A&amp;output=embed"
                ></iframe>
              </a>
              <br />
              <small>
                <a
                  rel="noopener noreferrer"
                  href="http://maps.google.com/maps?f=q&amp;source=embed&amp;hl=en&amp;geocode=&amp;q=13860+Redskin+Dr+Herndon,+VA+20171&amp;sll=37.0625,-95.677068&amp;sspn=49.176833,77.255859&amp;ie=UTF8&amp;hq=&amp;hnear=13860+Redskin+Dr,+Herndon,+Fairfax,+Virginia+20171&amp;ll=38.943523,-77.425804&amp;spn=0.020027,0.025749&amp;z=14&amp;iwloc=A"
                  style={{ textAlign: "left" }}
                >
                  View Larger Map
                </a>
              </small>
            </div>

            <div id="address">
              <p>
                Directions to BIGEYE Direct:
                <br />
                From the Capital Beltway (route 495)
              </p>
              <ol>
                <li>
                  Take VA-267 W via the ramp t<br />
                  Dulles Airport/I-495 N (Partial toll road) 13.1 miles
                </li>
                <li>
                  Take exit 9A to merge onto
                  <br />
                  VA-28 S/Sully Rd toward Centreville/Manassas - Partial toll
                  road 3.3 miles
                </li>
                <li>Take the Mclearen Rd exit toward VA-668 0.2 miles</li>
                <li>Merge onto McLearen Rd 0.2 miles</li>
                <li>Turn left at Towerview Rd</li>
                <li>Take 2nd Left onto Redskin Dr</li>
              </ol>
            </div>
            <div className="clear"></div>
          </div>
        </div>
        <div id="footer">
          <a
            rel="noopener noreferrer"
            href="http://maps.google.com/maps?f=q&amp;source=s_q&amp;hl=en&amp;geocode=&amp;q=13860+Redskin+Drive+Herndon,+VA+2017&amp;sll=37.0625,-95.677068&amp;sspn=52.637906,88.330078&amp;ie=UTF8&amp;hq=&amp;hnear=13860+Redskin+Dr,+Herndon,+Fairfax,+Virginia+20171&amp;z=17"
            target="_blank"
          >
            13860 Redskin Drive • Herndon, VA 2017
          </a>
          1
        </div>
        <div id="copyright">
          ©2011 BIGEYE Direct. All rights reserved. Sitemap
        </div>
      </div>
    </React.Fragment>
  )
}
